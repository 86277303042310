import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Container, Row, Col } from 'react-bootstrap'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import RightCol from '../components/RightCol'
import marked from 'marked'
import slugify from 'slugify'
class UserTemplate extends React.Component {
  render() {
    const { data } = this.props
    const features = data.filterPost.edges
    const authorpost = data.allSanityPost
    console.log('authorpost', authorpost)
    return (
      <Layout
        location={this.props.location}
        title={data.sanityPerson.name}
        theme="dark"
      >
        <SEO
          title={data.sanityPerson.name}
          description={this.props.data.site.siteMetadata.description}
        />
        <section className="user-page py-5">
          <Container className="py-5">
            <Row>
              <Col md={`${8} pr-md-5`}>
                {authorpost.edges.map(({ node }) => {
                  return (
                    <Row key={node.id} className="mb-5">
                      <LazyLoadComponent>
                        <Col md={`${4} mb-3 mb-sm-0`}>
                          <div
                            className="category-list-banner mb-2"
                            style={{
                              backgroundImage: `url(${node.mainImage &&
                                node.mainImage.asset &&
                                node.mainImage.asset &&
                                node.mainImage.asset.fluid &&
                                node.mainImage.asset.fluid.src})`,
                              backgroundPosition: `center center`,
                              backgroundSize: `cover`,
                              height: `180px`,
                            }}
                          />
                        </Col>
                        <Col md={8}>
                          <div className="category-list-content">
                            <h5 className="mb-4">
                              <Link
                                className="text-blue-13"
                                to={node.slug.current}
                              >
                                {node.title}
                              </Link>
                            </h5>
                            {node.excerpt ? (
                              <div
                                className="mb-4"
                                dangerouslySetInnerHTML={{
                                  __html: marked(node.excerpt),
                                }}
                              />
                            ) : null}

                            <div className="category-list-author">
                              <p>
                                <span className="text-secondary">By</span>{' '}
                                author
                                <span className="text-secondary">in</span>{' '}
                                <span className="text-blue text-capitalize">
                                  {node.categories.length !== 0
                                    ? node.categories.map(cat => (
                                        <Link
                                          to={`/category/${slugify(
                                            cat.title.toLowerCase()
                                          )}`}
                                          key={cat.id}
                                          className="cat-link"
                                        >
                                          {cat.title}{' '}
                                          <span className="d-inline">,</span>
                                        </Link>
                                      ))
                                    : null}
                                </span>{' '}
                                <span className="text-secondary">
                                  {node.publishedAt
                                    ? node.publishedAt
                                    : node._createdAt}
                                </span>
                              </p>
                            </div>
                          </div>
                        </Col>
                      </LazyLoadComponent>
                    </Row>
                  )
                })}
              </Col>
              <Col md={`${4} pl-md-5`}>
                <div className="right-content">
                  <RightCol features={features} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default UserTemplate

export const query = graphql`
  query UserTemplate($id: String!) {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    sanityPerson(id: { eq: $id }) {
      id
      name
    }

    filterPost: allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { featuredBlog: { eq: true } }
      limit: 4
    ) {
      totalCount
      edges {
        node {
          id
          title
          slug {
            current
          }
          excerpt
          featuredBlog
          _createdAt(fromNow: true)
          publishedAt(fromNow: true)
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          categories {
            id
            title
          }
          authors {
            _key
            person {
              name
              slug {
                current
              }
            }
          }
        }
      }
    }

    allSanityPost(
      filter: { authors: { elemMatch: { person: { id: { eq: $id } } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          title
          slug {
            current
          }
          excerpt
          featuredBlog
          _createdAt(fromNow: true)
          publishedAt(fromNow: true)
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          categories {
            id
            title
          }
          authors {
            _key
            person {
              id
              name
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`
