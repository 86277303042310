import React from 'react'
import { Link } from 'gatsby'
import { Form, Button } from 'react-bootstrap'
import './rightcol.css'
import './form.css'

class RightCol extends React.Component {
  render() {
    const { features } = this.props
    return (
      <div className="right-content">
        <div className="side-newsletter mb-5">
          <p className="text-dark-12">NEWSLETTER</p>
          <hr style={{ borderColor: '#cddeff' }} />
          <p>
            Subcribe to our newsletter by entering your email below to keep
            updated to our latest news and information.
          </p>
          <Form
            action="https://emailmarketing.webriqgoesmad.webriq.com/contact/add?version=2"
            data-id="qeOeJduFWw"
            method="post"
            id="ewf_subscriptionForm_qeOeJduFWw"
            className="style1 subscription"
          >
            <fieldset className="webform-custominputs">
              <div className="form-group inputs">
                <input
                  maxLength="60"
                  className="form-control"
                  id="email"
                  name="email"
                  size="lg"
                  type="email"
                  placeholder="info@mail.com"
                  required
                />
                <label htmlFor="email">Email</label>
              </div>
              <div className="checkboxes" />
              <div className="webform-lists" />
            </fieldset>
            <fieldset className="webform-options">
              <div id="captcha" className="mb-3">
                <div className="text-center d-inline-block mx-auto">
                  <div id="webformcaptcha" />
                </div>
              </div>
              <Button
                type="submit"
                name="submit"
                variant="primary"
                className="hvr-shadow"
                id="eesubmit"
                disabled=""
              >
                Subscribe
              </Button>
              <input
                type="hidden"
                name="publicaccountid"
                value="992d3f2b-bf67-4d2f-854d-33bf808d21c1"
              />
              <input
                type="hidden"
                name="publicformid"
                value="43c4c9c7-a55a-4fd2-a0a5-56ecdb7576f5"
              />
              <input
                type="hidden"
                name="returnUrl"
                value="https://webriq.com/subscription-successful"
              />
              <input
                type="hidden"
                name="activationReturnUrl"
                value="https://webriq.com/subscription-active"
              />
              <input
                type="hidden"
                name="alreadyactiveurl"
                value="https://webriq.com/"
              />
              <input type="hidden" name="activationTemplate" value="" />
              <input type="hidden" name="source" value="WebForm" />
              <input
                type="hidden"
                id="ewf_captcha"
                name="captcha"
                value="true"
              />
              <input type="hidden" name="notifyEmail" value="" />
              <button
                id="ewf_dismissForm"
                style={{
                  display: `none`,
                  position: `absolute`,
                  top: 0,
                  right: 0,
                  float: `right`,
                }}
              >
                X
              </button>
            </fieldset>
          </Form>
        </div>
        <div className="featured-list mb-5">
          <p className="text-dark-12">FEATURED POSTS</p>
          <hr style={{ borderColor: '#cddeff' }} />
          {features.map(({ node }) => {
            return (
              <div key={node.title} className="featured-item">
                <Link to={node.slug.current}>{node.title}</Link>
                <p className="featured-item-date text-secondary">
                  {node.publishedAt ? node.publishedAt : node._createdAt}
                </p>
              </div>
            )
          })}
        </div>
        <div className="category">
          <p className="text-dark-12">CATEGORIES</p>
          <hr style={{ borderColor: '#cddeff' }} />
          <ul className="m-0 list-unstyled category-list">
            <li>
              <Link to="/category/news-and-announcements/">
                News & Announcements
              </Link>
            </li>
            <li>
              <Link to="/category/technology-and-cms/">Technology & CMS</Link>
            </li>
            <li>
              <Link to="/category/seo/">SEO</Link>
            </li>
            <li>
              <Link to="/category/content-marketing/">Content Marketing</Link>
            </li>
            <li>
              <Link to="/category/marketing-automation/">
                Marketing Automation
              </Link>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default RightCol
